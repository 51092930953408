<template>
  <div>
    <el-input size="small" style="width:33.3%;margin-right:20px"
    v-model="code" placeholder="请输入内容"></el-input>
    <el-button type="primary" @click="submitForm()" size="small">查询</el-button>
    <pre class="errorMsg">{{errorMsg}}</pre>
  </div>
</template>

<script>
import request from '../../utils/request';

export default {
  name: 'journal',
  data() {
    return {
      code: '',
      errorMsg: '',
    };
  },
  methods: {
    submitForm() {
      const data = {
        code: this.code,
      };
      request.get('/mdm/registryController/getExceptionMsg', data)
        .then((res) => {
          this.errorMsg = res.result.msg;
          console.log(this.errorMsg);
        });
    },
  },
  mounted() {

  },
};
</script>

<style lang="less" scoped>
  .errorMsg{
    padding: 2px 20px 2px 20px;
    background-color: rgb(0, 0, 0);
    color: rgb(38, 228, 62);
  }
</style>
