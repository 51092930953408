var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        staticStyle: { width: "33.3%", "margin-right": "20px" },
        attrs: { size: "small", placeholder: "请输入内容" },
        model: {
          value: _vm.code,
          callback: function ($$v) {
            _vm.code = $$v
          },
          expression: "code",
        },
      }),
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "small" },
          on: {
            click: function ($event) {
              return _vm.submitForm()
            },
          },
        },
        [_vm._v("查询")]
      ),
      _c("pre", { staticClass: "errorMsg" }, [_vm._v(_vm._s(_vm.errorMsg))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }